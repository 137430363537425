import React from "react";

function CaseStudy() {
  return (
    <section className="section section--blog --section-normal-tb-padding">
      <div className="container">
        <div className="container__row --flex-center">
          <div className="container__col-md-6 --section-large-tb-padding">
            <p className="container__sub-heading">CASE STUDY</p>
            <h2 className="container__heading">
              CITRIX - Improving Product Documentation experience
            </h2>
            <p className="container__content --italic">
              “How Citrix created a simple, scalable Product Documentation
              experience and cut costs 65% with Hashout Tech”
            </p>
            <p className="container__content">
              {/* #JAMSTACK, #TECHNICALDOCUMENTATION */}
            </p>
            <a
              className="--action-link --download-icon"
              href="../../How-Citrix-created-a-simple-scalable-Product-Documentation-experience-with-Hashout.pdf"
              target="_blank"
            >
              download case study
            </a>
          </div>
          <div className="container__col-md-6 --flex-center">
            <img
              className="--bg-absolute-right"
              src="../../solutions/solutions/citrix.png"
              alt="CITRIX"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default CaseStudy;
