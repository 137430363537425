import React from "react";

function ToolsNAccelerators() {
  return (
    <section className="section section--tools section--tools-bg --section-large-tb-padding">
      <div className="container">
        <div className="container__row">
          <div className="container__col-xs-12">
            <h2 className="container__heading">Tools and Accelerators</h2>
            <div className="container__row">
              <div className="container__col-md-7">
                <p className="container__content">
                  Our continously efforts to build common repeatable solutions
                  as tools ensures providing faster time to market and redueced
                  effor on our engagements. These tools are also available to be
                  distrubted stand-alone, reach out to us to learn more.
                </p>
              </div>
            </div>
          </div>

          <div className="container__col-md-4 container__col-xs-12">
            <div className="card ">
              <img
                className="card__image"
                src="../../solutions/solutions/tools/broken-link-checker.png"
                alt="Broken Link Checker"
              />
              <a href="https://sitecheck.hashouttech.com/" target="_blank">
                <h5 className="card__title">Broken Link Checker</h5>
              </a>
              <p className="card__description">
                Fast, scalable tool to check broken links, images on your
                website...
              </p>
            </div>
          </div>
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/tools/google-cloud-search.png"
                alt="AEM-Salesforce Connector"
              />
              <a
                href="/blog/Understanding-Google-Custom-Search-Engine(CSE)-JSON-API"
                target="_blank"
              >
                <h5 className="card__title">Google Cloud Search</h5>
              </a>
              <p className="card__description">
                Integration of any website with cost-effective yet powerful
                Google Search APIs.
              </p>
            </div>
          </div>
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/tools/algolia.png"
                alt="Custom Events Framework"
              />
              <a
                href="/blog/Integrating-Algolia-Search-for-AEM-Sites"
                target="_blank"
              >
                <h5 className="card__title">Algolia</h5>
              </a>
              <p className="card__description">
                Integrate indexing as part of your CI/CD to start using Algolia
                Search platform.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default ToolsNAccelerators;
