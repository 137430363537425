import React from "react";

function JamstackBenifits() {
  return (
    <section className="section section--benefits --bg-gray-lightest --section-normal-tb-padding">
      <div className="container">
        <div className="container__row --flex-center --section-small-tb-padding">
          <div className="container__col-md-7 ">
            <h2 className="container__heading">Jamstack benefits</h2>
            <div className="container__benefits">
              <div className="container__row">
                <div className="container__col-md-2 container__col-xs-12">
                  <img
                    src="../../solutions/solutions/high-page-load-performance.png"
                    alt="High Page Load Performance"
                  />
                </div>
                <div className="container__col-md-10 container__col-xs-12">
                  <h5>High Page Load Performance</h5>
                  <div>
                    Everything is pre-rendered HTML with dynamic functionality
                    powered by APIs
                  </div>
                </div>
              </div>
              <div className="container__row">
                <div className="container__col-md-2  container__col-xs-12">
                  <img
                    src="../../solutions/solutions/cleaner-seperation-of-content-from-code.png"
                    alt="Cleaner separation of content from code"
                  />
                </div>
                <div className="container__col-md-10  container__col-xs-12">
                  <h5>Cleaner separation of content from code</h5>
                  <div>Makes it easy to evolve technology independently</div>
                </div>
              </div>
              <div className="container__row">
                <div className="container__col-md-2  container__col-xs-12">
                  <img
                    src="../../solutions/solutions/modern-technology-stack.png"
                    alt="Modern technology stack"
                  />
                </div>
                <div className="container__col-md-10  container__col-xs-12">
                  <h5>Modern technology stack</h5>
                  <div>
                    Ability to use React, Vue, and GO based static site which
                    makes it both fun and efficient to build along with
                    Git-based workflows and CDN for hosting which making it
                    highly secure too.
                  </div>
                </div>
              </div>
              <div className="container__row">
                <div className="container__col-md-2  container__col-xs-12">
                  <img
                    src="../../solutions/solutions/faster-to-build.png"
                    alt="Faster to build"
                  />
                </div>
                <div className="container__col-md-10  container__col-xs-12">
                  <h5>Faster to build</h5>
                  <div>
                    Several great tools and frameworks available to bootstrap
                    and build. Easy to use CI/CD pipelines to provide
                    branch-level continuous deployments increasing the speed and
                    efficiency of development.
                  </div>
                </div>
              </div>
              <div className="container__row">
                <div className="container__col-md-2  container__col-xs-12">
                  <img
                    src="../../solutions/solutions/highly-optimized-on-cost.png"
                    alt="Highly optimized on cost "
                  />
                </div>
                <div className="container__col-md-10  container__col-xs-12">
                  <h5>Highly optimized on cost </h5>
                  <div>
                    In most cases, CDN is the only hosting cost since everything
                    is pre-rendered which eliminates the need for expensive
                    servers with redundancy.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container__col-md-5 --flex-center">
            <img
              className="--bg-absolute-right"
              src="../../solutions/solutions/jamstack.png"
              alt="Jamstack"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default JamstackBenifits;
