import React from "react";
import Layout from "../components/Layout";
import SolutionsHelmet from "../components/SolutionsHelmet/SolutionsHelmet";
import ProductDocumentation from "../components/Solutions/ProductDocumentation/ProductDocumentation";
import LearnAboutUs from "../components/Solutions/LearnAboutUs/LearnAboutUs";
import CaseStudy from "../components/Solutions/CaseStudy/CaseStudy";
import JamstackBenefits from "../components/Solutions/JamstackBenefits/JamstackBenefits";
import ToolsNAccelerators from "../components/Solutions/ToolsNAccelerators_ProductDocumentation/ToolsNAccelerators";
import OurPerspectives from "../components/Solutions/OurPerspectives/OurPerspectives";
import { Helmet } from "react-helmet";

function solutionsWebExperiences() {
	const scrollTop = () => {
		window.scroll(0, 1);
	};
	return (
		<Layout> <Helmet>
		<meta charSet="utf-8" />
		   <title>
	   Hashout Technologies -  Product Documentation       </title>
		   <meta
			 name="description"
			 content="Improve product adoption with simple, scalable documentation experience."
		   ></meta></Helmet>
   
			<SolutionsHelmet />
			<ProductDocumentation />
			<CaseStudy />
			<JamstackBenefits />
			<ToolsNAccelerators />
			<OurPerspectives />
			<LearnAboutUs page="product-documentation"/>
			<a className="go-to-top" href="javascript:void(0);" onClick={scrollTop}>
				<img
					className="go-to-top__img"
					src="../../solutions/go-to-top.png"
					alt="Go to top"
				/>
			</a>
		</Layout>
	);
}
export default solutionsWebExperiences;
