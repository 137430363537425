import React, { useEffect, useState } from "react";
import ContactPopUp from "../../ContactPopUp";

function ProductDocumentation() {
  const [background, setBackground] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section className="section section--banner section--gray-bg">
      <div className="container">
        <div className="container__row">
          <div className="container__col-md-6 --section-large-tb-padding">
            <h1 className="container__heading font_769_992">
              Product Documentation{" "}
            </h1>
            <h2 className="container__sub-heading">
              Improve product adoption with{" "}
              <span className="--red-color">
                simple, scalable documentation experience.
              </span>
            </h2>
            <h6 className="container__content">
              Adopt docs-as-code approach and integrate with the development
              team to transform your documentation site into an agile powerhouse
              of information with our{" "}
              <span className="--red-color">
                Jamstack based product documentation solution.
              </span>
            </h6>
            <button className="container__button" onClick={openModal}>
              LET’S TALK
            </button>
          </div>
          <div className="container__col-md-6 --flex-center">
            <img
              className="container__img --bg-absolute-right"
              src="../../solutions/solutions/product-documentation.png"
              alt="Mobile Solutions"
            />
          </div>
        </div>
      </div>

      <ContactPopUp
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="Let's Connect"
        subTitle="Fill out this short form, and we'll get back to you within one
        working day."
        section="product-documentation-banner"
        page="product-documentation"
       
      />
    </section>
  );
}

export default ProductDocumentation;
